
function formatBytesHumanReadable(bytes,decimals) {
    if(bytes == 0) return '0 Byte';
    var k = 1000; // or 1024 for binary
    var dm = decimals + 1 || 3;
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    var i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

// Show preview image - bind this event to the onchange method of file upload
// e.g.
// var input = $("input[type='file']")[0];
// input.onchange = function() {previewImages(input, $("#previewUpload"))}
function previewImages(input, previewContainerDiv) {
    var fileList = input.files;
    var anyWindow = window.URL || window.webkitURL;
    previewContainerDiv.empty();
    for (var i = 0; i < fileList.length; i++) {
        //get a blob to play with
        var objectUrl = anyWindow.createObjectURL(fileList[i]);
        // for the next line to work, you need something class="preview-area" in your html
        var previewImage = "<div class='card col s12 m6 l3'>" +
            "<div class='card-image'>" +
            "<img class='thumbnail-image' src='"+ objectUrl +"'>" +
            "<div class='card-content truncate'>" +
            fileList[i].name + "<br>" + formatBytesHumanReadable(fileList[i].size, 1)
        "</div>" +
        "</div>" +
        "</div>"
        previewContainerDiv.append(previewImage);
        // get rid of the blob
        window.URL.revokeObjectURL(fileList[i]);
    }
}

//product search form
function toggleDisplayType(viewType) {
    if (document.getElementById("galleryView") == null) {
        return;
    }
    if (document.getElementById("listView") == null) {
        return;
    }

    if (viewType == 0) {
        document.getElementById("galleryView").style.display = "block";
        document.getElementById("listView").style.display = "none";
        $('#listViewOption').removeAttr('checked');
        $('#galleryViewOption').attr('checked', true);


        Cookies.set('product_display_type', 0);
    } else {
        document.getElementById("listView").style.display = "block";
        document.getElementById("galleryView").style.display = "none";
        $('#galleryViewOption').removeAttr('checked');
        $('#listViewOption').attr('checked', true);

        Cookies.set('product_display_type', 1);
    }
}


$(document).ready(function () {
    $("#hideRightPanel").click(
        function () {
            $("#rightPanel").toggle();
            $("#carPanel").toggle();
        }
    );

});