// $('.carousel.carousel-slider').carousel({full_width: true});

function showImageModal(imageUrl, showInNewTabButton) {
    $("#showImageModal-img").attr("src", "")
    $("#showImageModal-img").attr("src", imageUrl)
    if (showInNewTabButton) {
        var button = $('<a id="showImageModal-footer" href="' + imageUrl +'" target="_blank" class="modal-action modal-close waves-effect waves-green btn-flat"><i class="material-icons left">open_in_new</i>Open in new tab</a>')
        button.appendTo($("#showImageModal-footer"));
        button = $('<a id="closeImageModal-footer" href="#!" class="modal-action modal-close waves-effect waves-green btn-flat"><i class="material-icons left">close</i>Close</a>')
        button.appendTo($("#showImageModal-footer"));
    }

    var instance = M.Modal.getInstance($("#showImageModal"));
    instance.open();

};


$(document).ready(function () {
    $('.carousel').carousel({
        // indicators: true,
        no_wrap: true,
        dist: -300,
    });

    function createImageModal() {
        $('<div id="showImageModal" class="modal modal-fixed-footer" style="width: 95%; height: 95%; max-height: 100%; bottom: 10%">' +
            '<div class="modal-content" style="overflow: auto">' +
            '<div class="center">' +
            '<img class="modal-image-fullscreen" id="showImageModal-img" src=""/>' +
            '</div>' +
            '</div>' +
            '<div id="showImageModal-footer" class="modal-footer">' +
            '</div>' +
            '</div>' +
            '').appendTo($("body"));
    }

    createImageModal();

});


