$(function () {
    $("form.single-submission").submit(function () {
        $("form.single-submission .btn-submit").addClass("disabled");
        $(this).submit(function () {
            return false;
        });
        return true;
    });
    /*uncomment this to prevent ALL forms from multiple submission
     * 
     $("form").submit(function () {
        $(this).submit(function () {
            return false;
        });
        return true;
     });
     */
});
