/**
 * Created by GreenPig on 11/1/2019.
 */
$(document).ready(function () {
    M.AutoInit();
});

function seenNotifications() {
    $.ajax({
        method: "POST",
        url: "/notifications/seen",
        data: {},
        cache: false,
        beforeSend: function() {},
        complete: function(xhr, status) {},
        error: function(xhr, status, error) {},
        success: function (result, status, xhr) {
            $("#newNotificationBadge").remove();
        },
    })
}

function deleteNotification(event, notificationId, ele) {
    event.stopPropagation(); // Stop notification pane closing
    $.ajax({
        method: "POST",
        url: "/notifications/"+notificationId+"/delete",
        data: {},
        cache: false,
        beforeSend: function() {},
        complete: function(xhr, status) {},
        error: function(xhr, status, error) {},
        success: function (result, status, xhr) {
            ele.parentNode.remove(); //remove notification client-side
        },
    })
}

function incrementCartNotificationNumber() {
    var notificationNumber = $('.cart-notification-number').first().text();
    if (notificationNumber.length < 1) {
        notificaitonNumber = 0;
    } else {
        notificationNumber = parseInt(notificationNumber);
    }

    $('.cart-notification-number').text(notificationNumber + 1);
    $('.cart-notification-number').show(300);
}
